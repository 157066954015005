import {graphql} from '@apollo/client/react/hoc';
import decodeId from 'core/libs/helpers/decodeId';
import redirectIfNotSubscribed from 'core/libs/hoc/redirectIfNotSubscribed';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import log from 'core/libs/logger';
import {navigate} from 'gatsby';
import TrackDetails from 'modules/tracks/components/TrackDetails';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {branch, compose, lifecycle, setPropTypes} from 'recompose';
import NotFoundPage from 'theme/pages/NotFound';
import DEGREE from '../graphql/degree.graphql';
import SUBSCRIPTION from '../graphql/subscription.graphql';

const DegreeDetailsContainer = ({degree, ...props}) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TrackDetails {...props} isDegree track={degree} />;
};

DegreeDetailsContainer.propTypes = {
  degree: PropTypes.object.isRequired,
};

const enhance = compose(
  setPropTypes({
    trackSlug: PropTypes.string.isRequired,
  }),
  connect((state) => {
    return {
      authenticated: state.auth.authenticated,
    };
  }),
  graphql(DEGREE, {
    alias: 'withDegree',
    options: ({trackSlug}) => ({
      variables: {
        slug: trackSlug,
      },
    }),
    props: ({data, ownProps}) => {
      const {error, degrees, loading} = data;
      if (loading) return {loading};
      if (error) return {error};
      if (degrees.edges && !degrees.edges[0]) {
        log("Didn't find a degree for this slug", {
          degreeSlug: ownProps.trackSlug,
          currentUrl: typeof window !== 'undefined' && window.location.href,
        });
        return {error: "Didn't find a degree for this slug"};
      }
      const degree = degrees.edges[0].node;
      return {
        loading: false,
        degree: {
          ...degree,
          id: decodeId(degree.id),
          courses:
            degree.trackPtr.trackstepSet.edges &&
            degree.trackPtr.trackstepSet.edges.map(
              ({node: {course, deadline}}) => ({
                ...course,
                deadline,
                id: decodeId(course.id),
                stageSet: null,
                stages:
                  course.stageSet.edges &&
                  course.stageSet.edges.map(({node: stage}) => ({
                    ...stage,
                    id: decodeId(stage.id),
                    stepSet: null,
                    steps:
                      stage.stepSet.edges &&
                      stage.stepSet.edges.map(({node: step}) => ({
                        ...step,
                        id: decodeId(step.id),
                      })),
                  })),
              }),
            ),
        },
      };
    },
  }),
  graphql(SUBSCRIPTION, {
    skip: ({authenticated}) => !authenticated,
    props: ({ data }) => {
      const {error, recurrentPayment, loading} = data;
      if (loading) return {loading};
      if (error) return {error};
      return {subscription: recurrentPayment?.status }
    },
  }),
  spinnerWhileLoading(({loading}) => loading),
  branch(
    ({error}) => error,
    () => NotFoundPage,
  ),
  connect((state, ownProps) => {
    const degreeId = ownProps.degree.id;
    return {
      coursesProgress: state.user.progress.course || {},
      trackProgress:
        (degreeId &&
          state.user.progress.track &&
          state.user.progress.track[degreeId]) ||
        'not started',
      stepsProgress: state.user.progress.step || {},
      stagesProgress: state.user.progress.stage || {},
      availableCertificates: state.user.certificates.availableCertificates,
      profile: state.user.profile,
    };
  }),
);

export default enhance(DegreeDetailsContainer);
