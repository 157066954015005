import {Match} from '@reach/router';
import DegreeDetailsContainer from 'modules/degrees/containers/DegreeDetailsContainer';
import React from 'react';
import NotFoundPage from 'theme/pages/NotFound';

const DegreeDetailsPage = () => {
  return (
    <Match path="/degrees/:trackSlug">
      {({match}) =>
        match ? (
          <DegreeDetailsContainer isDegree trackSlug={match.trackSlug} />
        ) : (
          <NotFoundPage />
        )
      }
    </Match>
  );
};

export default DegreeDetailsPage;
